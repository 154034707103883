<template>
  <component
    :is="component"
    v-editable="isVisualEditor ? block : null"
    :blok="block"
    :context="context"
    class="font-content"
    :class="block.component"
  />
</template>

<script setup>
import { defineAsyncComponent, onMounted } from 'vue'

const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  context: {
    type: Object,
    default: () => {},
  },
})

const isVisualEditor = import.meta.env.VITE_IS_EDITOR
let aboutToUnload = false

const component = defineAsyncComponent({
  // the loader function
  loader: async () => {
    try {
      const componentDefinition = await import(
        `../blocks/${props.block.component}.vue`
      )
      return componentDefinition
    } catch (e) {
      if (aboutToUnload) {
        console.log(
          `Block import failed because the user changed the page. Ignoring the error.`,
        )
      } else {
        throw e
      }
    }
  },
  // A component to use if the load fails
  errorComponent: 'div',
})

onMounted(() => {
  window.addEventListener('beforeunload', () => {
    aboutToUnload = true
  })
})
</script>
